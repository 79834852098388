<template>
  <div class="">
    <button  @click="googleLogin">Google ログイン</button>
    <router-link :to="{name: 'mypage'}">閉じる</router-link>
  </div>
</template>

<script>
// import firebase from 'firebase/app'

export default {
  created () {
  },
  mounted () {
  },
  methods: {
    googleLogin: function() {
      // const provider = new firebase.auth.GoogleAuthProvider()

      // firebase.auth().signInWithRedirect(provider)
      this.$router.push('/signin/loading')
    }
  }
}
</script>

<style scoped>
section{
  background: pink;
}
</style>
